import { Box, Typography } from "@mui/material";
import BlobSvg from '../../assets/svg/blob.svg'
import Cathedral from '../../assets/image/cathedral.png'

function BlobReims(props){
    return(
        <Box id={'Reims'} sx={{backgroundImage:`url(${BlobSvg})`,backgroundRepeat:'no-repeat',backgroundSize:'contain'}}
        maxWidth={350} maxHeight={350} display={'flex'}  flexDirection={'row'} {...props} >
        
        
        <Box flex={1} display={'flex'}  >
          <img alt='Cathedral de Reims en France' style={{filter:'drop-shadow(5px 5px 5px #222)',objectFit:'contain'}} src={Cathedral} height={'auto'} width={'100%'} />           
        </Box>
        <Box  flex={1} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <Typography textAlign={'center'} >Confectionné à :</Typography>
          <Typography textAlign={'center'} variant='h4' fontFamily={'Dancing Script'}>Reims</Typography>
          <Typography textAlign={'center'} variant='h6' fontFamily={'Dancing Script'}>France</Typography>
        </Box>

      </Box>
    )
}

export default BlobReims