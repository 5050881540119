import { Box, IconButton } from "@mui/material";
import LogoBlitz from "../assets/svg/logo";
import { useContext, useEffect, useState } from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LayoutContext } from "../provider/LayoutProvider";

function interpolate(start, end, percentage) {
    return start + (end - start) * percentage;
}

function WrapperLogo(props){
 
    const [baseScale,setBaseScale] = useState(1)
    const [scale,setScale] = useState(1)
    const [top,setTop] = useState(window.innerHeight/2)
    const goalScale = 70 / window.innerWidth
    const toTranslate = 40
    const {showTopBar, setShowTopBar} = useContext(LayoutContext)


    // const [visualVH,setVisualVH] = useState(window.visualViewport.height)
    // const [innerHeight,setInnerHeight] = useState(window.innerHeight)


    
    const computeBaseScale = () => {
        const percentageScroll = Math.min(1, window.scrollY * 1.7 / window.innerHeight)
        if(percentageScroll>= 1) {
            setShowTopBar(true)
        }else{
            setShowTopBar(false)
        }
        const toGoal = Math.min(window.innerHeight,window.innerWidth)
        let ratio
        if(window.innerWidth>window.innerHeight){

            ratio = toGoal/(window.innerWidth+300)
        }else{
            ratio = toGoal/(window.innerWidth)
        }
        setBaseScale(ratio)
        setScale(ratio)
    }

    const handleScroll = () => {
        const percentageScroll = Math.min(1, window.scrollY * 1.7 / window.innerHeight)
        if(percentageScroll>= 1) {
            setShowTopBar(true)
        }else{
            setShowTopBar(false)
        }
        const toGoal = Math.min(window.innerHeight,window.innerWidth)
        let ratio
        if(window.innerWidth>window.innerHeight){

            ratio = toGoal/(window.innerWidth+300)
        }else{
            ratio = toGoal/(window.innerWidth)
        }
        const newTop = interpolate(window.innerHeight/2,toTranslate,percentageScroll)
        setTop(newTop)
        const newScale = interpolate(ratio,goalScale,percentageScroll)
        setScale(newScale)
        
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        computeBaseScale()
        return () => {
            window.removeEventListener('scroll',handleScroll);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <>
        <Box id='wrapper' sx={{width:'100%',position:'fixed',zIndex: showTopBar? -111 : 1004,opacity:showTopBar? 0 : 1}} {...props}>
            <LogoBlitz id='LogoBlitz' style={{ position:'absolute',transform:`translateY(-50%) scale(${scale})`,top:top }}  />
        </Box>
        <Box zIndex={1002} width={"100%"} height={"100svh"} position={'absolute'} sx={{display: props.show ? 'none' : 'block'}}>
            <Box  position={'absolute'} bottom={0} left={'50%'} sx={{transform:'translateX(-50%)'}}>
                <IconButton onClick={()=>{window.scrollTo({top:window.innerHeight - 80})}} size="large" sx={{color:'black'}}>
                    <ArrowDownwardIcon fontSize="large"/>
                </IconButton>
            </Box>
        </Box>
    </>
    )
}

export default WrapperLogo
