import { Box } from "@mui/material"
import BasicHeader from "../components/header/BasicHeader"
import Artisanal from "../components/informations/Artisanal"
import WrapperLogo from "../components/WrapperLogo"
import MainContent from "../components/MainContent"
import From from "../components/informations/From"
import BlobInfo from "../components/informations/BlobInfo"
import Landing from "../components/Landing"





function LandingPage(props){
    return (
      <>
        <Landing/>
        {/* <-------------- Artisanal ------------------>*/}
        <Box width={'100%'} display={'flex'} justifyContent={'center'} px={{lg:'250px'}}>
          <BasicHeader title={'Un bijou artisanal'}/>
        </Box>
        <Artisanal/>


        {/* <-------------------------------->*/}
     
        {/* <-------------- BLOOOOP ------------------>*/}
        <Box mt={7} width={'100%'} display={'flex'} justifyContent={'center'} px={{lg:'250px'}}>
          <BasicHeader my={4} title={'Une provenance unique'}/>
        </Box>
        <BlobInfo  py={2}/>
        {/* <-------------------------------->*/}

        {/* <-------------- From ------------------>*/}
        <Box mt={7} width={'100%'} display={'flex'} justifyContent={'center'} px={{lg:'250px'}}>
          <BasicHeader title={`Plus qu'un simple bijou`}/>
        </Box>
        <From mt={5}/>
        {/* <-------------------------------->*/}

        <Box my={7} sx={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center'}}>
          <Box width={'100%'} display={'flex'} justifyContent={'center'} px={{lg:'250px'}}>
           <BasicHeader title={'Nouvelle collection'}/>
          </Box>
          {/* <Present/> */}
          <MainContent/>
        </Box>
        <WrapperLogo className="LogoWrapper" dis={props.dis} show={props.show} />
      </>
    )
}

export default LandingPage