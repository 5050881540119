import { Box } from "@mui/material";
import {ProductList} from "../components/ProductList"

function MainContent(){


    return (
    <Box  width={"100%"} px={{xs:'0',lg:'250px'}}  >
        <ProductList/>
    </Box>
    )
}

export default MainContent