import { Box, Typography } from "@mui/material";
import BraceletImg from '../../assets/image/jewelry/bracelet_ocean.webp'

function From(props){


    return(
        <Box marginTop={10} display={'flex'} flexDirection={{xs:'column',lg:'row'}}  {...props}>
          <Box mr={5}  sx={{boxShadow:'12px -14px 0px 5px rgba(207,68,98,0.5)'}} className={'TestShadowAnim'} >
            <img style={{objectFit:'cover'}} src={BraceletImg} width={'100%'} height={'100%'} alt='Bracelet sur un fond ocean' />
          </Box>
          <Box mx={4} mt={4} >
            <Typography mt={2} color={'text.secondary'}>L'histoire de cette pierre remonte à l'Égypte antique, une époque où elle était considérée comme porteuse de chance et de bonheur. Taillée en perle par les Assyriens sous le nom de "Süryani boncugu", cette perle existe depuis l'Antiquité.</Typography>
            <Typography mt={2} color={'text.secondary'}>Fournies par un prestataire situé dans la province turque de Mardin, à la frontière de la Syrie et de l'Irak, ces perles sont issues de la ville antique de Mésopotamie, fondée par les Assyriens il y a environ 300 ans avant J.-C. La population actuelle perpétue l'exploitation et le commerce de cette pierre turquoise, lui conférant une renommée dans tout le Moyen-Orient.</Typography>
          </Box>
        </Box>
    )
}

export default From