import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Paper, Radio, RadioGroup, Toolbar, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import SortIcon from '@mui/icons-material/Sort';
import VerticalModal from "../components/modal/VerticalModal";
import ProductData from "../fakeData/product.json"
import { ProductCard } from "../components/ProductList";
import ENV from "../config/build";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import navigationData from '../fakeData/navigation.json'

const TYPE_DRAWER = {
    filter: {
      label:'filter',
      title: 'Filtrer par : '
    },
    sort: {
      label : 'sort',
      title : 'Trier par : '
    } 
}

const SORT_LIST = [
  {
    name:'popularity',
    label:'Popularité'
  },
  {
    name:'lowest_price',
    label:'Prix le plus bas'
  },
  {
    name:'highest_price',
    label:'Prix le plus haut'
  },
  {
    name:'new',
    label:'Nouveautés'
  },

]

const FILTER_LIST = [
  {
    name:'Type',
    data:['Bracelet','Collier','Boucle d oreilles']
  },
  {
    name:'Matière',
    data:['Polyester tissé','Satin']
  }


]

function DynamicModal(props){

}

function CollectionPage(props){


    const [activeMenu,setActiveMenu] = useState(false)
    const [drawerType,setDrawType] = useState(TYPE_DRAWER.filter)
    const {slug} = useParams()
    const navigate = useNavigate()


    const closeFunction = () => {
      setActiveMenu(false)
    }

    const openDrawer = (event) => {
      if(event.target.name){
        setDrawType(TYPE_DRAWER[event.target.name])
      }else{
        setDrawType(TYPE_DRAWER['sort'])
      }
      setActiveMenu(true)
    }

    const DynamicModal = () => {
      switch (drawerType.label) {
        case TYPE_DRAWER.filter.label:
          return <FilterByModal/>

        case TYPE_DRAWER.sort.label:
          return <SortByModal/>

        default:
          return <SortByModal/>
      }
    }

    function getLabelByLink(link) {
      const item = navigationData.find(item => item.link === link);
      return item ? item.label : null;
    }

    function getDescriptionByLink(link){
      const item = navigationData.find(item => item.link === link);
      return item ? item.description : null;
    }

    return(
    <>
        <Toolbar sx={{height:80}}/>
        <Box width={'100%'} p={3} bgcolor={'rgba(207, 68, 98, 0.1)'}>
          <Typography variant="h6">{getLabelByLink(slug)}</Typography>
          <Typography variant="body2">{getDescriptionByLink(slug)}</Typography>
        </Box>
        <Box elevation={0} bgcolor={'rgba(207, 68, 98, 0.1)'} component={Paper}  sx={{width:'100%',borderRadius:0,display:'flex',p:0,minHeight:56,columnGap:0.5}}  >
          <Button name={'filter'} onClick={openDrawer} sx={{flex:1,borderRadius:0,bgcolor:'white'}}  endIcon={<TuneIcon/>}>Filtre</Button>
          <Button name={'sort'} onClick={openDrawer} sx={{flex:1,borderRadius:0,bgcolor:'white'}} endIcon={<SortIcon/>}>Trier par</Button>
        </Box> 
        <Box flex={1} bgcolor={'rgba(207, 68, 98, 0.1)'} display={'grid'} gap={0.5} py={1} px={0} gridTemplateColumns={'repeat(2,1fr)'} gridAutoRows={'1fr'}>

          {
            ProductData.sort((a,b) => 0.5 - Math.random() ).map(({title,price,slug},index)=>(
            <Box onClick={()=>{navigate('/product/'+slug)}}   key={index} rowGap={2} elevation={0} p={2} display={'flex'} flexDirection={'column'} component={Paper} sx={{borderRadius:0}} >
                <Box flex={1} >
                    <img  width={'100%'} height={'80%'} alt="Ok dokz " style={{objectFit:'contain'}} src={process.env.PUBLIC_URL + `/img/${slug}/transparent.png`}/>
                </Box>
                <Box sx={{opacity: Math.random() > 0.5 ? '1' : '0'}}>
                  <Chip label={'Nouveauté'} variant="outlined" color="primary" size="small" sx={{borderRadius:0}}/>
                </Box>
                <Box>
                  <Typography variant="body2" className="OneLine" >{title}</Typography>
                  <Typography variant="caption" textAlign={'left'}>{price}€</Typography>
                </Box>
            </Box>
            ))
          }
        </Box>
        <VerticalModal title={drawerType.title} active={activeMenu} closeFunction={closeFunction} >
          <Box p={3} display={'flex'} flexDirection={'column'} rowGap={2}>
            <DynamicModal/>
          </Box>
        </VerticalModal>
    </>
          
    )
}  

function FilterByModal(props){
  const [valueRadio,setValueRadio] = useState(FILTER_LIST[0].name)

  const handleChange = (e) => {
    setValueRadio(e.target.value)
  }

  return(
    <>
        {
          FILTER_LIST.map(({name,data},index)=>(
            <FormControl key={index} component={'fieldset'} variant="standard">
              <FormLabel component="legend">{name} :</FormLabel>
              <FormGroup value={valueRadio} key={index} onChange={handleChange} name="filter-by-radiogroup" >
                {
                  data.map((value,index)=>(
                      <FormControlLabel key={index} control={<Checkbox />} label={value}/>
                    ))
                }
              </FormGroup>
               { 
                index !== FILTER_LIST.length - 1 && <Divider/> 
               }
            </FormControl>
          ))
        }
    </>
  )
}

function SortByModal(props){

  const [valueRadio,setValueRadio] = useState(SORT_LIST[0].name)

  const handleChange = (e) => {
    setValueRadio(e.target.value)
  }

  return(
    <FormControl>
    <RadioGroup  value={valueRadio}  onChange={handleChange} name="sort-by-radiogroup" >
      {
        SORT_LIST.map(({name,label},index)=>(
          <FormControlLabel key={index} value={name} control={<Radio/>} label={label}/> 
        ))
      }
    </RadioGroup>
  </FormControl>
  )
}

export default CollectionPage