import { Box, IconButton, Paper, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

function VerticalModal({ active, closeFunction, title,children }) {

    return (
        <>
            <Box onClick={()=>{closeFunction()}} bgcolor={'rgba(0,0,0,0.4)'} zIndex={1299} position={'fixed'} height={'calc(100vh - 150px)'} width={'100%'} sx={{ display: active ? 'block' : 'none' }} >
            </Box>
            <Box zIndex={1300} display={'flex'} flexDirection={'column'} position={'fixed'} height={'calc(100vh - 150px)'} width={"100%"} className={active ? 'DrawerMenu ToggleDrawerMenu' : 'DrawerMenu '} >
            <Box bgcolor={'whitesmoke'} flex={1}>
                <Box width={'100%'} position={'relative'} p={2} borderRadius={0} component={Paper}>
                    <Typography textAlign={'center'} variant="h5">{title ? title : 'Choix :'}</Typography>
                    <Box position={'absolute'} right={10} top={'50%'} sx={{ transform: 'translateY(-50%)' }}>
                        <IconButton onClick={()=>{closeFunction()}} sx={{ color: 'black' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {children && (children)}
            </Box>
            </Box>
        </>
    )
}

export default VerticalModal