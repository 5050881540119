import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material"
import fakeData from "../fakeData/product.json"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "react-router-dom"
import ENV from "../config/build"


function ProductCard({title,price,slug,index}){


    return(
        <Card elevation={0} sx={{height:'100%',display:'flex',flexDirection:'column' , "&:hover":{transform:'scale(1.05)'},transition:'transform 0.5s ease' }} className="testShadow">
        <Box height={150} p={1} >
          <img alt="Bracelet" height={'100%'} width={'100%'} style={{objectFit: 'contain'}} src={
             process.env.PUBLIC_URL + `/img/${slug}/transparent.png` 
          } />
        </Box>
        <CardContent sx={{flex:1,display:'flex',flexDirection:'column'}}>
          <Typography flex={1}  variant="body1" component="div">
            {title}
          </Typography>
          <Typography pt={1} textAlign={'left'} variant="body2" color="text.secondary">
                {price}€
            </Typography>
        </CardContent>
        <CardActions sx={{justifyContent:'space-between'}}>
          {/* <Button size="small" variant='contained' >Ajouter</Button> */}
          <Button size="small" variant='outlined' fullWidth>Voir</Button>
        </CardActions>
      </Card>
    )
}


function ProductList(props){

   

    return(
      <Box sx={{padding:2,paddingTop:0}} display={'flex'} rowGap={5} alignItems={'center'} flexDirection={'column'}>
        <Box gridAutoRows={'1fr'} sx={{paddingTop:3,width:'100%',display:'grid',gap:5,gridTemplateColumns:{xs:'repeat(auto-fill,minmax(150px,1fr))',xl:'repeat(auto-fill,minmax(180px,1fr))'}}}>
            {
                fakeData.map(({title,price,slug},index)=>{
                  
                  if(index >= 4) return null
                  else {
                    return (
                      <ScrollAnimation key={index} animateOnce={true} animateIn={index % 2 === 0 ? 'fadeIn':'fadeIn' }>
                      <ProductCard title={title} price={price} index={index} slug={slug}/>
                      </ScrollAnimation>
                    )
                  }

                })
            }
        </Box>

        <Button sx={{maxWidth:200}} LinkComponent={Link} to={'nouvelle-collection-2023'}  variant="contained">Voir plus</Button>

      </Box>
    )
}

export {ProductCard,ProductList}