// ThemeContext.js
import React, { createContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ENV from '../config/build.js';

const base_path = ENV.IS_DEV ? '/' : '/blif/'


const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
  const [showTopBar, setShowTopBar] = useState(false);
  const location = useLocation()

  const toggleNav = () => {
    setShowTopBar((prev) => !prev);
  };
  useLayoutEffect(() => {
    document.documentElement.scrollTo({top:0,left:0,behavior:'instant'});
  }, [location.pathname]);

  useEffect(()=>{
    if(location.pathname !== '/'){
      setShowTopBar(true)
    }
  },[location])

  return (
    <LayoutContext.Provider value={{ showTopBar, setShowTopBar, toggleNav }}>
      {children}
    </LayoutContext.Provider>
  );
};

export { LayoutProvider, LayoutContext };
