import { Box, IconButton, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';

function Footer(props){

    return(
        <Toolbar  sx={{bgcolor:'rgba(207, 68, 98, 0.5)' ,backdropFilter:'blur(3px)',color:'white',flexDirection:'column',width:'100%'}}>
           <Box my={1}>
                <Stack direction={'row'} justifyContent={'center'}  >
                    <IconButton size="large" sx={{color:'white'}}>
                        <FacebookIcon fontSize="large"/>
                    </IconButton>
                    <IconButton size="large" sx={{color:'white'}}>
                        <InstagramIcon fontSize="large"/>
                    </IconButton>
                    <IconButton size="large" sx={{color:'white'}}>
                        <TwitterIcon fontSize="large"/>
                    </IconButton>
                    <IconButton size="large" sx={{color:'white'}}>
                        <GoogleIcon fontSize="large"/>
                    </IconButton>
                </Stack>
                <Box >
                    <Typography variant="caption" display={'block'} >Copyright ⓒ 2023 BLIF. Tous droits réservés.</Typography>
                    <Typography variant="caption" display={'block'}>BLIF et le logo sont des marques déposées.</Typography>
                </Box>
           </Box>
        </Toolbar>
    )
}

export default Footer