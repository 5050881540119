import { Box, Fade, Toolbar, Typography } from "@mui/material";
import ProductData from "../fakeData/product.json"
import { useParams } from "react-router-dom";
import ENV from "../config/build";
import { useState } from "react";
import SwipeableViews from 'react-swipeable-views';
import BasicHeader from "../components/header/BasicHeader";


function Product(props){
    const {slug} = useParams()
    const item = getDescriptionByLink(slug)
    const [activeStep,setActiveStep] = useState(0)
    const images = [
        {
          label: 'San Francisco – Oakland Bay Bridge, United States',
          imgPath: `/img/${slug}/1.png`

        },
        {
          label: 'Bird',
          imgPath: `/img/${slug}/2.png`
        },
        {
          label: 'DEzd',
          imgPath: `/img/${slug}/transparent.png`
        }
      ];
    
    const maxStep = images.length

    function getDescriptionByLink(slug){
        const item = ProductData.find(item => item.slug === slug);
        return item ;
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
      const handleStepChange = (step) => {
        setActiveStep(step);
      };

    return(
        <>
            <Toolbar sx={{height:80}}/>
            <Box flex={1} width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'} >
                <SwipeableViews axis="x" index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                    {
                       images.map((step, index) => (
                        <div key={step.label}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                              component="img"
                              sx={{
                                height: 255,
                                display: 'block',
                                maxWidth: 400,
                                overflow: 'hidden',
                                width: '100%',
                                objectFit: 'cover'
                              }}
                              // src={step.imgPath}
                              src={
                                process.env.PUBLIC_URL + step.imgPath
                              }
                              alt={step.label}
                            />
                          ) : null}
                        </div>
                      )) 
                    }
                </SwipeableViews>
                <BasicHeader title={item.title}/>
            </Box>
        </>
        )
}

export default Product