import { createTheme } from "@mui/material";

const theme = createTheme({
    typography:{
        fontFamily:'Poppins'
    },
    palette:{
        primary:{
            main:'#cf4462'
        },
        secondary:{
            main:'#e09d85'
        }
    }
})

export default theme