function DiamondLogo(props){


    return(
  <svg
    viewBox="0 0 60 60"
    id="svg5"
    {...props}
  >
    <defs id="defs5" />
    <g
      id="#308a9dff"
      className="diamond"
      transform="translate(-439.83059,-507.55594)"
    >
      <path
        fill="#308a9d"
        opacity={1}
        d="m 448.11,516.79 c 7.77,-2.38 15.96,-1.99 23.94,-2.99 6.16,-0.67 12.38,-0.42 18.54,0.2 -0.26,0.66 -0.77,1.98 -1.03,2.64 0.71,3.14 1.26,6.35 2.46,9.36 -0.22,1.32 -0.43,2.65 -0.64,3.98 -4.87,6.64 -10.02,13.14 -13.93,20.41 -2.12,3.64 -4.63,7.03 -6.87,10.59 -1.01,0.23 -2,0.48 -2.99,0.73 -6.68,-2.13 -8.72,-9.59 -12.48,-14.75 -2.96,-3.77 -3.95,-8.59 -6.72,-12.44 -0.74,-5.9 -1.1,-11.81 -0.28,-17.73 m 8.23,-0.48 c 0.32,1.38 3.97,1.36 4.27,-0.02 -1.23,-0.79 -3.06,-0.79 -4.27,0.02 m 16.72,1.08 c -7.81,-0.38 -15.29,2.16 -23.05,2.45 5.46,2.1 7.56,8.05 11.76,11.7 3.55,-0.44 7.12,-0.67 10.66,-1.18 0.34,0.44 1.02,1.32 1.36,1.76 -0.15,-0.5 -0.44,-1.5 -0.59,-1.99 1.75,-0.12 3.52,-0.21 5.29,-0.32 1.03,-2.64 2.04,-5.29 3.13,-7.9 2.23,-0.53 2.85,-2.92 3.92,-4.63 0.98,-0.04 1.97,-0.08 2.96,-0.12 -4.93,-1.78 -10.32,0.41 -15.44,0.23 m 6.9,12.36 c 2.36,-0.73 4.75,-1.43 7.13,-2.09 -0.22,-3.07 -0.54,-6.14 -0.8,-9.19 -2.06,3.78 -4.19,7.53 -6.33,11.28 m -28.93,-7.58 c 0.57,3.41 1,6.85 1.12,10.31 2.79,0.21 5.57,-0.07 8.33,-0.48 -4.51,-1.67 -6.16,-6.69 -9.45,-9.83 m 30.72,9.11 c -0.17,0.38 -0.51,1.15 -0.68,1.53 -3.56,0.95 -7.33,1.01 -10.74,2.55 0.51,1.83 1.03,3.68 1.56,5.53 0.63,-2 1.3,-3.99 2.1,-5.92 2.01,4.7 -1.04,9.95 -0.53,14.94 3.87,-6.35 8.88,-12.06 11.84,-18.93 -1.19,0.09 -2.38,0.19 -3.55,0.3 m -28.02,5.57 c 3.41,0.02 6.96,-2.95 10.15,-1.05 -0.19,-0.36 -0.58,-1.09 -0.77,-1.45 2.03,-0.15 4.07,-0.22 6.1,-0.43 -5.23,-1.52 -10.87,0.4 -15.48,2.93 m 0.36,-2.59 c 0.55,0.52 0.55,0.52 0,0 m 8.61,2.99 c 1.56,4.61 2.58,9.38 4.14,13.99 0.4,1.56 1.47,2.77 2.64,3.82 -0.24,0.5 -0.72,1.51 -0.96,2.01 l 1.45,0.19 c 0.92,-3.83 2.49,-7.73 1.74,-11.73 -0.73,-3.38 -1.81,-6.68 -2.39,-10.09 -2.2,0.64 -4.41,1.24 -6.62,1.81 m -7.42,0.57 c 2.13,4.42 4.89,8.47 8.29,12.01 -0.66,-4.06 -1.26,-8.14 -2.33,-12.11 -1.99,0.11 -3.98,0.14 -5.96,0.1 z"
        id="path3"
      />
    </g>
  </svg>


    )

}

export default DiamondLogo