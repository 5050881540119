import { Box } from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import BlobReims from "../blob/BlobReims";
import BlobMardin from "../blob/BlobMardin";

function BlobInfo(props){
    return (
          <Box width={'100%'} px={{xs:'0',lg:'250px'}} alignItems={'center'} justifyContent={'center'} columnGap={8} display={'flex'} flexDirection={{xs:'column',lg:'row'}} {...props} >
            <ScrollAnimation animateIn='fadeIn'>
              <BlobReims width={'calc(100vw - 32px)'} height={'100vh'} />
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn'>
              <BlobMardin width={'calc(100vw - 32px)'} height={'100vh'}/>
            </ScrollAnimation>
          </Box>
    )
}

export default BlobInfo