import { AppBar, Badge, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import MenuIco from '../../assets/ico/MenuIco.jsx';
import ShoppingCartIco from '../../assets/ico/ShoppingCartIco.jsx';
import styled from '@emotion/styled';
import { useContext, useState } from "react";
import LogoBlitz from "../../assets/svg/logo.js";
import DiamondLogo from "../../assets/svg/DiamondLogo";
import { Link, useNavigate } from "react-router-dom";
import { LayoutContext } from "../../provider/LayoutProvider.js";
import navItems from "../../fakeData/navigation.json"

const drawerWidth = 240;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    backgroundColor:'rgba(207, 68, 98, 1)',
     top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));


function TopBar(props){
  
    const [mobileOpen, setMobileOpen] = useState(false);
    const { windowa } = props;
    const {showTopBar} = useContext(LayoutContext)
    const nav = useNavigate()

    const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
    };
  
    const Draw =  (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} columnGap={1}> 
            <Typography  variant="h4" sx={{ my: 1 }}>
              BLIF
            </Typography>
            <Box width={30} display={'flex'} height={30} >
              <DiamondLogo/>
            </Box>
          </Box>
          <Typography mb={1} fontFamily={'Dancing Script'}>Un rappel de chance & de protection</Typography>
          <Divider />
          <List>
            {navItems.map(({label,link},index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton LinkComponent={Link} to={link}  sx={{ textAlign: 'left' }}>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )
    
    const container = windowa !== undefined ? () => window().document.body : undefined;  
  
    const callFn = () => {
      window.scrollTo({top:'100vh',behavior:'smooth'})
      nav('')
    }
    return(
      <Box width={'100%'} zIndex={1003}>
        <AppBar  elevation={0} sx={{backdropFilter:'blur(5px)',height:80,bgcolor:'rgba(207, 68, 98, 0.5)'}}   position='fixed'>
          <Toolbar sx={{position:'relative',justifyContent:'space-between',alignItems:'center',height:'100%'}}>
              <IconButton size='large' sx={{color:'black', mr: 2, display: { sm: 'none' } }} onClick={handleDrawerToggle}>
                  <MenuIco width={35}/>
              </IconButton>

              <LogoBlitz onClick={callFn} style={{position:'absolute',left:'50%',transform:'translateX(-50%)',opacity:showTopBar ? 1 : 0}} width={70}/>
              
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map(({label,link},index) => (
              <Button LinkComponent={Link} to={link} key={index} sx={{ color: '#fff' }}>
              {label}
              </Button>
              ))}
              </Box>
              <IconButton size='large' sx={{color:'black'}}>
                <StyledBadge badgeContent={2} color='error'>
                  <ShoppingCartIco width={35}/>
                </StyledBadge>
              </IconButton>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer  
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                zIndex:3336,
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}>
                
            {Draw}
          </Drawer>
        </nav>
      </Box>
    )
  
  }

export default TopBar