import { Box, Typography } from "@mui/material";
import ImageCraft from '../../assets/image/handmade.webp'

function Artisanal(props){
    return(
      <Box width={'100%'} px={{xs:'0',lg:'250px'}} maxHeight={700} display={'flex'} flexDirection={{xs:'column',lg:'row'}} {...props}>
        <Box p={2} pb={0} >
          <img  style={{objectFit:'cover',width:'100%',height:'100%'}} src={ImageCraft} alt='Un bijou fait main' />
        </Box>
        <Box mx={4} mt={4} >
          <Typography color={'text.secondary'}>Le travail de nouvelles matières, formes, textures et couleur le tout dans le respect des matières naturelles et un savoir-faire fait main.</Typography>
        </Box>
      </Box>
    )
}

export default Artisanal