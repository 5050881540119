import { Box, Typography } from "@mui/material";
import BlobSvg from '../../assets/svg/blob_2.svg'
import Mosque from '../../assets/image/mosque.png'

function BlobMardin(props){
    return(
        <Box id={'Mardin'} sx={{backgroundImage:`url(${BlobSvg})`,backgroundRepeat:'no-repeat',backgroundSize:'contain'}}
          maxWidth={350} maxHeight={350} display={'flex'} flexDirection={'row'} {...props} >
          <Box  flex={1} ml={3} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
            <Typography noWrap textAlign={'center'} >Pierres & ornements de :</Typography>
            <Typography textAlign={'center'} variant='h4' fontFamily={'Dancing Script'}>Mardin</Typography>
            <Typography textAlign={'center'} variant='h6' fontFamily={'Dancing Script'}>Turquie</Typography>
          </Box>
          <Box flex={1} display={'flex'}  >
            <img alt='Grande mosque de Mardin en Turquie' style={{filter:'drop-shadow(5px 5px 5px #222)',objectFit:'contain'}} src={Mosque} height={'90%'} width={'100%'} />           
          </Box>
        </Box>
    )
}

export default BlobMardin