import { Box, Typography } from "@mui/material";

function BasicHeader(props){

    return(
        <Box my={2} component={'div'} p={1} border={'0.3px black solid'} boxShadow={'12px 14px 0px -2px rgba(207,68,98,0.5)'}  {...props} width={props.width ? `calc(${props.width} - 30px)` : 'calc(100% - 30px)'} >
            <Typography variant={props.variant ? props.variant : 'h5'} textAlign={'center'}>{props.title}</Typography>
            { props.subheader &&
                <Typography variant="h6" color={'text.secondary'} textAlign={'center'}>{props.subheader}</Typography>
            }
        </Box> 
      )
}

 

export default BasicHeader