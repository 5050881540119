import { CssBaseline, ThemeProvider, Typography } from '@mui/material';
import './App.css';
import './styles/present.css'
import './styles/menu_drawer.css'
import './styles/text.css'
import theme from './themes/config.js';
import Footer from './components/layout/Footer.jsx';
import TopBar from './components/layout/TopBar.jsx';
import LandingPage from './pages/LandingPage.jsx';
import { Outlet, RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import { LayoutProvider } from './provider/LayoutProvider.js';
import CollectionPage from './pages/CollectionPage.jsx';
import ENV from './config/build.js';
import Product from './pages/Product.jsx';




function App(){

  const router = createBrowserRouter([
    {
      
      path:  '' ,
      element: <RootLayout/>,
      children:[
        {
          path:'',
          element: <LandingPage/>,
        },
        {
          path:'product/:slug',
          element:<Product/>
        },
        {
          path:':slug',
          element: <CollectionPage/>
        }
      ]
    }
  ],{
    basename: process.env.PUBLIC_URL
  });
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <RouterProvider router={router}/>
      </ThemeProvider>
  )
}


function RootLayout(props) {

  return (
    <LayoutProvider>
        <div className="App" style={{overflowX:'hidden'}}>
          <TopBar/>
            <Outlet/>
          <Footer/>
        </div>
    </LayoutProvider>

  );
}

export default App;
