import { Box } from "@mui/material"
import '../styles/typewriting.css'
import VideoPresentation from "../assets/video/video.mp4"
import VideoPresentationH from "../assets/video/videow.mp4"
import Thumbnail from "../assets/image/thumbnail.png"

 

function Landing(){



    return (
        <Box  width={'100%'} position={'relative'} height={'100svh'} display={'flex'} justifyContent={'center'} flexDirection={'column'} className={'header'} bgcolor={'#fbeff2'}>

            <Box zIndex={-1} position={'absolute'} height={'100%'} width={'100%'}>
                <video id="myvideo" autoPlay muted playsInline loop poster={Thumbnail} width={'100%'} height={'100%'} style={{objectFit:'cover'}}   >
                    <source id="myvideosource" src={ window.innerHeight > window.innerWidth ? VideoPresentation : VideoPresentationH} type="video/mp4"/> 
                </video>
            </Box>
        </Box> 
    )
}



export default Landing

